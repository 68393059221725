import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { useState } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";


export default function Card({ amount }: { amount: string }) {
    const stripe = useStripe();
    const elements = useElements();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setIsLoading(true);
        const { error }: {error: StripeError} = await stripe.confirmPayment({
            elements: elements,
            confirmParams: {
              return_url: `${window.location.origin}/completion`,
              payment_method_data: {
                billing_details: {
                  name: name,
                  email: email
                }
              }
            },
          });
          if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message || 'Unfortunately there was an error while processing your payment.');
          } else {
            setMessage("Unfortunately there was an error while processing your payment.");
          }
        setIsLoading(false);
    }
    return (
        <>
            <Form.Input
                label='name'
                placeholder='John Doe'
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <Form.Input
                label='Email'
                placeholder='johndoe@example.com'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <PaymentElement id="payment-element" />
              {
                message && (
                  <Message negative>
                    <Message.Header>Payment Failed</Message.Header>
                    <p>{message}</p>
                  </Message>
                )
              }
              <Modal.Actions className="modal-actions">
                <Button primary onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleSubmit(event)} loading={isLoading} disabled={isLoading}>Pay USD ${amount}</Button>
              </Modal.Actions> 
        </>
    )
}