import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Header, Icon, Segment } from "semantic-ui-react";

export default function Completion() {
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const [paymentIntent] = useState(searchParams.get('payment_intent'));
    const [redirectStatus] = useState(searchParams.get('redirect_status'));


    const success = async() => {
        if (paymentIntent && redirectStatus) {
            const {data} = await axios({
            method: 'POST',
            url: 'https://api.roni.genuineassignment.com/public/v1/stripes/success',
            data: {
                intentId: paymentIntent
            }
            })
        }
    }

    useEffect(() => {
        success()
    }, [paymentIntent])

    return (
        <Segment placeholder>
            <Header icon>
            <Icon name='check circle outline' color='green' />
                Thank you for your purchase!
            </Header>
            <Segment raised>Total paid amount $100</Segment>
      </Segment>
    )
}