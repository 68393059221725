import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useState } from "react";
import { Button, Container, Form, Header, Input, Label, Modal } from "semantic-ui-react";

import Card from "../components/card";

export default function Payment() {
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise] = useState(loadStripe('pk_test_51L52aeDo17ticfh1Y93ZuFF7dMAtZgNk7ZcVuKt1Qgf6BLeJutRhCYaaYrn97L2Ji7zwlEWhMhWyjHKhTrPeEe6U00X2dv48jH'));
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const createPaymentIntent = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault()
    try {
      setIsLoading(true)
      const {data} = await axios({
        method: 'POST',
        url: 'https://api.roni.genuineassignment.com/public/v1/stripes/create-payment-intent',
        data: {
          amount: Number(amount) * 100
        }
      })
      setClientSecret(data.data.client_secret)
      setOpen(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container textAlign='center' fluid>
      <Header>
        <Header.Content className="header-content">Roni Card Payment</Header.Content>
      </Header>
      <Form>
        <Input labelPosition='right' type='text'>
          <Label basic>$</Label>
          <Form.Input placeholder='Enter Amount in usd' value={amount} onChange={(event) => setAmount(event.target.value)} required />
        </Input>
        <Button type='button' className="modal-button" secondary content='Make a Payment' loading={isLoading} disabled={isLoading} onClick={(event) => createPaymentIntent(event)} />
      </Form>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="tiny"
        dimmer={true}
      > 
        <Modal.Header className="modal-header">Roni mentoring</Modal.Header>
        <Form>
          <Modal.Content className="modal-content">
            {stripePromise && clientSecret &&   (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <Card amount={amount} />
              </Elements>
            )}
          </Modal.Content>
        </Form>
      </Modal>
    </Container>
  )
}