import { BrowserRouter, Route, Routes } from "react-router-dom";

import Completion from "./pages/completion";
import Payment from "./pages/payment";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index  element={<Payment />} />
        <Route path="completion" element={<Completion />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
